// src/components/Header.js
import React from 'react';
import '../styles/Header.css'; // Import the CSS file for the header

const Header = () => {
  return (
    <div className="header-image">
      {/* You can add content inside the header if needed */}
    </div>
  );
};

export default Header;
