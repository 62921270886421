// src/pages/QuizPage.js
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axiosInstance from '../axiosInstance';
// import '../styles/QuizPage.css'; 
// const QuizPage = () => {
//   const navigate = useNavigate();
//   const [currentQuestion, setCurrentQuestion] = useState(0);
//   const [answers, setAnswers] = useState([]);
//   const questions = JSON.parse(sessionStorage.getItem('questions'));
//   if (!questions || questions.length === 0) {
//     return <p>No questions available for this quiz.</p>;
//   }
//   const userName = sessionStorage.getItem('username');
//   const king = JSON.parse(sessionStorage.getItem('king'));
//   const handleAnswerChange = (answer) => {
//     const updatedAnswers = [...answers];
//     updatedAnswers[currentQuestion] = answer;
//     setAnswers(updatedAnswers);
//   };
//   const calculateScore = () => {
//     let score = 0;
//     questions.forEach((question, index) => {
//       if (answers[index] === question.correct_option) {
//         score += 1;
//       }
//     });
//     return score;
//   };
//   const handleNext = async () => {
//     // Check if the user has selected an answer for the current question
//     if (!answers[currentQuestion]) {
//       alert('You have not selected any answer for this question.');
//       return;
//     }
//     if (currentQuestion < questions.length - 1) {
//       // Move to the next question
//       setCurrentQuestion(currentQuestion + 1);
//     } else {
//       // Confirm before submitting the score and viewing results
//       const confirmSubmission = window.confirm('Are you sure to submit your answers and view results?');
//       if (confirmSubmission) {
//         // Fetch existing scores to check for duplicates
//         try {
//           const response = await axiosInstance.get('/user-scores/');
//           const existingScores = response.data;
//           const normalizedUserName = userName.toLowerCase(); // Normalize the username
//           const duplicate = existingScores.some(score =>
//             score.user_name.toLowerCase() === normalizedUserName && score.king === king.id
//           );

//           if (duplicate) {
//             alert('You have already completed the quiz for this king. Please try another king\'s quiz.');
//           } else {
//             // Save answers and navigate to Results page
//             sessionStorage.setItem('userAnswers', JSON.stringify(answers));
//             sessionStorage.setItem('username', userName);

//             const score = calculateScore();
//             submitScore(userName, score, king.id);
//           }
//         } catch (error) {
//           console.error(error);
//           alert('Failed to fetch scores. Please try again later.');
//         }
//       } 
//       // Else: do nothing, the user has canceled the submission
//     }
//   };
//   const submitScore = async (userName, score, king) => {
//     if (!king) {
//       alert("King information is missing. Cannot submit score.");
//       return;
//     }
//     const out_of = questions.length;
//     const percentage = Math.round((score / out_of) * 100) + '%'; 
//     const scoreData = {
//       user_name: userName,
//       score: score,
//       out_of: out_of,
//       percentage: percentage,
//       king: king,
//       date_completed: new Date().toISOString(),
//     };
//     try {
//       const response = await axiosInstance.post('/submit-score/', scoreData);
//       if (response.status === 201 || response.status === 200) {
//         navigate('/results');
//       } else {
//         throw new Error('Failed to submit score');
//       }
//     } catch (error) {
//       console.error(error);
//       alert('Failed to submit score. Please try again later.');
//     }
//   };
//   return (
//     <div className="quiz-page">
//       <div className='background-bottom'>
//         <div className='quiz-card'>
//           <h2>Quiz Questions (أسئلة الاختبار)</h2>
//           {/* <h3>Quiz on {questions[currentQuestion].category.name}</h3> */}
//           <p><strong>{currentQuestion + 1}:</strong> {questions[currentQuestion].question_text}</p>
//           <form>
//             <label>
//               A.
//               <input
//                 type="radio"
//                 name="answer"
//                 value="A"
//                 checked={answers[currentQuestion] === 'A'}
//                 onChange={() => handleAnswerChange('A')}
//               />
//               <span>{questions[currentQuestion].option_a}</span>
//             </label>
//             <label>
//               B.
//               <input
//                 type="radio"
//                 name="answer"
//                 value="B"
//                 checked={answers[currentQuestion] === 'B'}
//                 onChange={() => handleAnswerChange('B')}
//               />
//               <span>{questions[currentQuestion].option_b}</span>
//             </label>
//             <label>
//               C.
//               <input
//                 type="radio"
//                 name="answer"
//                 value="C"
//                 checked={answers[currentQuestion] === 'C'}
//                 onChange={() => handleAnswerChange('C')}
//               />
//               <span>{questions[currentQuestion].option_c}</span>
//             </label>
//             <button type="button" onClick={handleNext}>
//               {currentQuestion === questions.length - 1 ? 'Finish' : 'Next'}
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default QuizPage;


// src/pages/QuizPage.js
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axiosInstance from '../axiosInstance';
// import '../styles/QuizPage.css'; 

// const QuizPage = () => {
//   const navigate = useNavigate();
//   const [currentQuestion, setCurrentQuestion] = useState(0);
//   const [answers, setAnswers] = useState([]);
//   const [userInput, setUserInput] = useState('');
//   const questions = JSON.parse(sessionStorage.getItem('questions'));
//   if (!questions || questions.length === 0) {
//     return <p>No questions available for this king.</p>;
//   }
//   const userName = sessionStorage.getItem('username');
//   const king = JSON.parse(sessionStorage.getItem('king'));
//   const handleInputChange = (event) => {
//     setUserInput(event.target.value.toUpperCase());
//   };
//   const calculateScore = () => {
//     let score = 0;
//     questions.forEach((question, index) => {
//       if (answers[index] === question.correct_option) {
//         score += 1;
//       }
//     });
//     return score;
//   };
//   const handleNext = async () => {
//     // Ensure the user has entered an answer
//     if (!userInput) {
//       alert('Please enter your answer before proceeding.');
//       return;
//     }
//     const updatedAnswers = [...answers];
//     updatedAnswers[currentQuestion] = userInput;
//     setAnswers(updatedAnswers);
//     // Clear the input field for the next question
//     setUserInput('');
//     if (currentQuestion < questions.length - 1) {
//       setCurrentQuestion(currentQuestion + 1);
//     } else {
//       const confirmSubmission = window.confirm('Are you sure to submit your answers and view results?');
//       if (confirmSubmission) {
//         try {
//           const response = await axiosInstance.get('/user-scores/');
//           const existingScores = response.data;
//           const normalizedUserName = userName.toLowerCase();
//           const duplicate = existingScores.some(score =>
//             score.user_name.toLowerCase() === normalizedUserName && score.king === king.id
//           );
//           if (duplicate) {
//             alert('You have already completed the quiz for this king. Please try another king\'s quiz.');
//           } else {
//             sessionStorage.setItem('userAnswers', JSON.stringify(updatedAnswers));
//             sessionStorage.setItem('username', userName);
//             const score = calculateScore();
//             submitScore(userName, score, king.id);
//           }
//         } catch (error) {
//           console.error(error);
//           alert('Failed to fetch scores. Please try again later.');
//         }
//       }
//     }
//   };
//   const submitScore = async (userName, score, king) => {
//     if (!king) {
//       alert("King information is missing. Cannot submit score.");
//       return;
//     }
//     const out_of = questions.length;
//     const percentage = Math.round((score / out_of) * 100) + '%'; 
//     const scoreData = {
//       user_name: userName,
//       score: score,
//       out_of: out_of,
//       percentage: percentage,
//       king: king,
//       date_completed: new Date().toISOString(),
//     };
//     try {
//       const response = await axiosInstance.post('/submit-score/', scoreData);
//       if (response.status === 201 || response.status === 200) {
//         navigate('/results');
//       } else {
//         throw new Error('Failed to submit score');
//       }
//     } catch (error) {
//       console.error(error);
//       alert('Failed to submit score. Please try again later.');
//     }
//   };
//   return (
//     <div className="quiz-page">
//       <div className='background-bottom'>
//         <div className='quiz-card'>
//           <h2>Quiz Questions (أسئلة الاختبار)</h2>
//           <p><strong>{currentQuestion + 1}:</strong> {questions[currentQuestion].question_text}</p>
//           <div className="options">
//             <p>A. {questions[currentQuestion].option_a}</p>
//             <p>B. {questions[currentQuestion].option_b}</p>
//             <p>C. {questions[currentQuestion].option_c}</p>
//           </div>
//           <input
//             type="text"
//             placeholder="Enter A, B, or C"
//             value={userInput}
//             onChange={handleInputChange}
//             maxLength={1} // Ensures user only inputs a single letter
//           />
//           <button type="button" onClick={handleNext}>
//             {currentQuestion === questions.length - 1 ? 'Finish' : 'Next'}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default QuizPage;


// src/pages/QuizPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import '../styles/QuizPage.css'; 

const QuizPage = () => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const questions = JSON.parse(sessionStorage.getItem('questions'));

  if (!questions || questions.length === 0) {
    return <p>No questions available for this quiz.</p>;
  }
  const userName = sessionStorage.getItem('username');
  const king = JSON.parse(sessionStorage.getItem('king'));
  const handleInputChange = (event) => {
    const input = event.target.value.toUpperCase();
    setUserInput(input);
    setErrorMessage(''); // Clear the error message when user starts typing
  };
  const isValidAnswer = (answer) => {
    return answer === 'A' || answer === 'B' || answer === 'C';
  };
  const handleNext = async () => {
    // Validate the user input
    if (!userInput) {
        alert('Please enter your answer before proceeding.');
        return;
    }
    if (!isValidAnswer(userInput)) {
      alert('Your answer must either be A, B, or C.')
      return;
    }
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestion] = userInput;
    setAnswers(updatedAnswers);
    // Clear the input field for the next question
    setUserInput('');
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      const confirmSubmission = window.confirm('Are you sure to submit your answers and view results?');
      if (confirmSubmission) {
        try {
          const response = await axiosInstance.get('/user-scores/');
          const existingScores = response.data;
          const normalizedUserName = userName.toLowerCase();
          const duplicate = existingScores.some(score =>
            score.user_name.toLowerCase() === normalizedUserName && score.king === king.id
          );
          if (duplicate) {
            alert('You have already completed the quiz for this king. Please try another king\'s quiz.');
          } else {
            sessionStorage.setItem('userAnswers', JSON.stringify(updatedAnswers));
            sessionStorage.setItem('username', userName);

            const score = calculateScore();
            submitScore(userName, score, king.id);
          }
        } catch (error) {
          console.error(error);
          alert('Failed to fetch scores. Please try again later.');
        }
      }
    }
  };
  const calculateScore = () => {
    let score = 0;
    questions.forEach((question, index) => {
      if (answers[index] === question.correct_option) {
        score += 1;
      }
    });
    return score;
  };

  const submitScore = async (userName, score, king) => {
    if (!king) {
      alert("King information is missing. Cannot submit score.");
      return;
    }

    const out_of = questions.length;
    const percentage = Math.round((score / out_of) * 100) + '%'; 
    const scoreData = {
      user_name: userName,
      score: score,
      out_of: out_of,
      percentage: percentage,
      king: king,
      date_completed: new Date().toISOString(),
    };

    try {
      const response = await axiosInstance.post('/submit-score/', scoreData);
      if (response.status === 201 || response.status === 200) {
        navigate('/results');
      } else {
        throw new Error('Failed to submit score');
      }
    } catch (error) {
      console.error(error);
      alert('Failed to submit score. Please try again later.');
    }
  };

  return (
    <div className="quiz-page">
      <div className='background-bottom'>
        <div className='quiz-card'>
          <h2>Quiz Questions (أسئلة الاختبار)</h2>
          <div className="question-container">
          <p><strong>{currentQuestion + 1}:</strong> {questions[currentQuestion].question_text}</p>
          </div>
          <div className="options">
            <p><strong>A.</strong>  {questions[currentQuestion].option_a}</p>
            <p><strong>B.</strong>  {questions[currentQuestion].option_b}</p>
            <p><strong>C.</strong>  {questions[currentQuestion].option_c}</p>
          </div>

          <input
            type="text"
            placeholder="Enter A, B, or C"
            value={userInput}
            onChange={handleInputChange}
            maxLength={1} // Ensures the user only inputs a single letter
          />
          
          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <button type="button" onClick={handleNext}>
            {currentQuestion === questions.length - 1 ? 'Finish' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizPage;
