
// // src/pages/ViewResult.js
// import React from 'react';
// import '../styles/ViewResult.css'; // Import the CSS file

// const ViewResult = () => {
// //   const navigate = useNavigate();
//   const username = sessionStorage.getItem('username');
//   const score = sessionStorage.getItem('score')
//   const total_questions = sessionStorage.getItem('total_questions')
//   const percentage = sessionStorage.getItem('percentage');
//   // Check if questions or userAnswers are null or invalid
//   if (!total_questions  || total_questions === 0 ) {
//     return (
//       <div className="results-page">
//         <h1>No quiz data found. Please complete the quiz to see your final score.</h1>
//       </div>
//     );
//   }
//   // Function to determine the appreciation message based on the percentage
//   const getAppreciationMessage = () => {
//     const percentageNumber = parseInt(percentage, 10); // Convert the percentage to an integer

//     if (percentageNumber >= 80) {
//       return "Congratulations!";
//     } else if (percentageNumber >= 40 && percentageNumber < 80) {
//       return "Good trial!";
//     } else {
//       return "Fair trial!";
//     }
//   };
//   return (
//     <div className="results-page">
//       <div className="background-bottom">
//         <div className="results-card">
//           <h1>{getAppreciationMessage()} {username} !!</h1>
//           <h2>You scored  {score} / {total_questions} ;  ({percentage}%)</h2>
//           <h1> HAPPY NATIONAL DAY !!</h1>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default ViewResult;


import React from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import '../styles/ViewResult.css'; // Import the CSS file

const ViewResult = () => {
  const navigate = useNavigate();  // Initialize navigate
  const username = sessionStorage.getItem('username');
  const score = sessionStorage.getItem('score');
  const total_questions = sessionStorage.getItem('total_questions');
  const percentage = sessionStorage.getItem('percentage');
  // Check if questions or userAnswers are null or invalid
  if (!total_questions || total_questions === 0) {
    return (
      <div className="results-page">
        <h1>No quiz data found. Please complete the quiz to see your final score.</h1>
      </div>
    );
  }
  // Function to determine the appreciation message based on the percentage
  const getAppreciationMessage = () => {
    const percentageNumber = parseInt(percentage, 10); // Convert the percentage to an integer
    if (percentageNumber >= 80) {
      return "Congratulations!";
    } else if (percentageNumber >= 40 && percentageNumber < 80) {
      return "Good trial!";
    } else {
      return "Fair trial!";
    }
  };
  // Function to handle the button click with confirmation
  const handleExitClick = () => {
    // Show confirmation dialog
    const confirmed = window.confirm("Are you sure to exit?");
    if (confirmed) {
      navigate('/');  // Navigate to homepage if confirmed
    }
  };
  return (
    <div className="results-page">
      <div className="background-bottom">
            <div className="results-card">
                <h1>{getAppreciationMessage()} {username} !!</h1>
                <h2>You scored {score} / {total_questions} ; ({percentage}%)</h2>
                <h1> HAPPY NATIONAL DAY !!</h1>
                {/* Add button to exit to homepage */}
                <div className="exit-button-container">
                    <button className="exit-button" onClick={handleExitClick}>
                        Exit
                    </button>
                </div>
            </div>
      </div>
    </div>
  );
};
export default ViewResult;
