// src/axiosInstance.js
import axios from 'axios';
import BASE_URL from './config';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    // You can set other options here (e.g., headers)
});

export default axiosInstance;
