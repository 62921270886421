// src/components/Layout.js
// import React from 'react';
// import Header from './Header'; // Import the Header component

// const Layout = ({ children }) => {
//   return (
//     <>
//       <Header /> {/* The header will appear on all pages */}
//       <main>{children}</main> {/* Page-specific content will be passed as children */}
//     </>
//   );
// };
// export default Layout;


// import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import Header from './Header'; // Import the Header component
// import NProgress from './nprogressConfig'; // Import custom NProgress configuration
import 'nprogress/nprogress.css'; // Import the default NProgress CSS

// function usePageLoading() {
//   const location = useLocation();

//   useEffect(() => {
//     NProgress.start(); // Start NProgress on route change
//     return () => NProgress.done(); // Stop NProgress when route change is complete
//   }, [location]);
// }

function Layout({ children }) {
  /*usePageLoading(); */ // Call our custom hook inside Layout
  return (
    <div>
      <Header /> {/* The header will appear on all pages */}
      <main>{children}</main> {/* Page-specific content will be passed as children */}
    </div>
  );
}
export default Layout;
