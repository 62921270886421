// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;



// src/App.js
// import React from 'react';
// import React, { useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route , useLocation } from 'react-router-dom';
// import HomePage from './pages/HomePage';
// import KingDetailsPage from './pages/KingDetailsPage';
// import QuizPage from './pages/QuizPage';
// import ResultsPage from './pages/ResultsPage';
// import ViewResult from './pages/ViewResult';
// import './App.css';
// import Layout from './pages/Layout';
// import NProgress from './nprogressConfig'; // Import custom NProgress configuration
// import 'nprogress/nprogress.css'; // Import the default NProgress CSS
// /*import LoadingSpinner from './components/LoadingSpinner'; */ // Import the global spinner
// /* import { LoadingProvider } from './components/LoadingContext'; */ // Import the loading provider

// function usePageLoading() {
//   const location = useLocation();

//   useEffect(() => {
//     NProgress.start(); // Start NProgress on route change
//     return () => NProgress.done(); // Stop NProgress when route change is complete
//   }, [location]); // Run effect on location change
// }

// function App() {
//   usePageLoading(); // Call our custom hook to trigger NProgress
//   return (
//     <Router>
//       {/* <LoadingProvider> */}
//         <div className="App">
//         {/* /*<LoadingSpinner />  Always render the loading spinner */}
//           <Layout>
//             <Routes>
//                 {/* HomePage route: Year selection and king search */}
//                 <Route path="/" element={<HomePage />} />

//                 {/* KingDetailsPage route: Displays the king details */}
//                 <Route path="/king-details" element={<KingDetailsPage />} />

//                 {/* QuizPage route: Displays the quiz questions one at a time */}
//                 <Route path="/quiz" element={<QuizPage />} />

//                 {/* ResultsPage route: Displays the quiz results and explanations */}
//                 <Route path="/results" element={<ResultsPage />} />

//                 {/* ViewResults route: Displays the final result scores */}
//                 <Route path="/view-results" element={<ViewResult />} />

//                 {/* Add additional routes as needed */}
//             </Routes>
//           </Layout>
//         </div>
//       {/* </LoadingProvider> */}
//     </Router>
//   );
// }
// export default App;



import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import KingDetailsPage from './pages/KingDetailsPage';
import QuizPage from './pages/QuizPage';
import ResultsPage from './pages/ResultsPage';
import ViewResult from './pages/ViewResult';
import './App.css';
import Layout from './pages/Layout';
import 'nprogress/nprogress.css'; // Import the default NProgress CSS

function App() {
  return (
    <Router>
      <div className="App">
        <Layout>
          <Routes>
            {/* HomePage route: Year selection and king search */}
            <Route path="/" element={<HomePage />} />
            {/* KingDetailsPage route: Displays the king details */}
            <Route path="/king-details" element={<KingDetailsPage />} />
            {/* QuizPage route: Displays the quiz questions one at a time */}
            <Route path="/quiz" element={<QuizPage />} />
            {/* ResultsPage route: Displays the quiz results and explanations */}
            <Route path="/results" element={<ResultsPage />} />
            {/* ViewResults route: Displays the final result scores */}
            <Route path="/view-results" element={<ViewResult />} />
            {/* Add additional routes as needed */}
          </Routes>
        </Layout>
      </div>
    </Router>
  );
}
export default App;
