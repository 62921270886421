
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/KingDetails.css'; // Import the new CSS file
import axiosInstance from '../axiosInstance';

const KingDetailsPage = () => {
  const navigate = useNavigate();
  const username = sessionStorage.getItem('username');
  const king = JSON.parse(sessionStorage.getItem('king'));
  const achievements = JSON.parse(sessionStorage.getItem('achievements'));
  // Extract the first word of the king's name
  const kingFirstName = king.name.split(' ')[1];
  // Dynamically set the image path based on king's ID
  const kingImage = require(`../assets/kings_images/${king.id}.png`);
  // State to track the current achievement index
  const [currentAchievementIndex, setCurrentAchievementIndex] = useState(0);
  const handleStartQuiz = async () => {
    const confirmStart = window.confirm('Are you sure to start the quiz?');
    if (confirmStart) {
      try {
        const response = await axiosInstance.get('/user-scores/');
        const existingScores = response.data;
        const normalizedUserName = username.toLowerCase();
        const duplicate = existingScores.some(
          score => score.user_name.toLowerCase() === normalizedUserName && score.king === king.id
        );
        if (duplicate) {
          alert('Sorry, you have already completed the quiz for this king.');
          navigate('/');
        } else {
          const quizResponse = await axiosInstance.get(`/questions/king/${king.id}/`);
          const quizQuestions = quizResponse.data;
          sessionStorage.setItem('questions', JSON.stringify(quizQuestions));
          navigate('/quiz');
        }
      } catch (error) {
        console.error('Error fetching user scores:', error);
        alert('Failed to check for existing quiz attempts. Please try again.');
      }
    }
  };
  // Navigate through achievements
  const handleNextAchievement = () => {
    if (currentAchievementIndex < achievements.length - 1) {
      setCurrentAchievementIndex(currentAchievementIndex + 1);
    }
  };
  const handlePreviousAchievement = () => {
    if (currentAchievementIndex > 0) {
      setCurrentAchievementIndex(currentAchievementIndex - 1);
    }
  };
  return (
    <div className="king-details-page">
    {/* Section with the  background image where cards will be placed */}
    <div className="background-bottom">
      {/* King Details Card */}
      <div className="king-card king-details">
        <h2>King {kingFirstName}</h2>
        <p><strong>Between</strong> {king.reign_start_year} - {king.reign_end_year}</p>
        <img src={kingImage} alt={`King ${kingFirstName}`} />
      </div>
      {/* King Achievements Card */}
      <div className="king-card king-achievements">
        <h3>Achievements الإنجازات</h3>
        {achievements.length > 0 ? (
          <div>
            <h4>{currentAchievementIndex + 1}. {achievements[currentAchievementIndex].category.name}</h4>
            <p>{achievements[currentAchievementIndex].description}</p>
            <div className="achievement-navigation">
              <button 
                className="arrow-button" 
                onClick={handlePreviousAchievement} 
                disabled={currentAchievementIndex === 0}
              >
                ← Previous
              </button>
              {/* Show the Start Quiz button only on the last achievement */}
              {currentAchievementIndex === achievements.length - 1 ? (
                <button onClick={handleStartQuiz}>Start Quiz</button>
              ) : (
                <button onClick={handleNextAchievement} disabled={currentAchievementIndex === achievements.length - 1}>
                  Next →
                </button>
              )}
            </div>
          </div>
        ) : (
          <p>No achievements found for this king.</p>
        )}
      </div>
    </div>
  </div>
  );
};
export default KingDetailsPage;
