
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ResultsPage.css'; // Import the CSS file

const ResultsPage = () => {
  const navigate = useNavigate();
  const username = sessionStorage.getItem('username');
  const questions = JSON.parse(sessionStorage.getItem('questions'));
  const userAnswers = JSON.parse(sessionStorage.getItem('userAnswers'));
  // State to track the current question index
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  // Check if questions or userAnswers are null or invalid
  if (!questions || !userAnswers || questions.length === 0 || userAnswers.length === 0) {
    return (
      <div className="results-page">
        <h1>No quiz data found. Please complete the quiz to see your results.</h1>
      </div>
    );
  }
  // Calculate the user's score
  const calculateScore = () => {
    let score = 0;
    questions.forEach((question, index) => {
      if (userAnswers[index] === question.correct_option) {
        score++;
      }
    });
    return score;
  };
  const handleViewResult = () => {
    const confirmStart = window.confirm('Are you sure to view your final score?');
    if (confirmStart) {
      sessionStorage.setItem('score', score);
      sessionStorage.setItem('total_questions', total_questions)
      sessionStorage.setItem('username', username);
      sessionStorage.setItem('percentage', percentage);
      navigate('/view-results');
    }
  };
  // Calculate the percentage score
  const calculatePercentage = (score) => {
    return Math.round((score / questions.length) * 100);
  };
  const score = calculateScore();
  const percentage = calculatePercentage(score);
  const total_questions = questions.length
  // Navigate through questions
  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };
  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };
  // Current question data
  const currentQuestion = questions[currentQuestionIndex];
  return (
    <div className="results-page">
      <div className="background-bottom">
        <div className="results-card">
          <h1>Answers & Explanations</h1>
          <h3>Question {currentQuestionIndex + 1} of {questions.length}</h3>
          {/* Display the current question, user answer, correct answer, and explanation */}
          <div className="question-details">
            <p><strong>Question:</strong> {currentQuestion.question_text}</p>
            <p><strong>Your Answer:</strong> {userAnswers[currentQuestionIndex]}</p>
            <p><strong>Correct Answer:</strong> {currentQuestion.correct_option}</p>
            <p><strong>Explanation:</strong> {currentQuestion.explanation}</p>
          </div>
          <div className="question-navigation">
            <button 
              className="arrow-button" 
              onClick={handlePreviousQuestion} 
              disabled={currentQuestionIndex === 0}
            >
              ← Previous
            </button>
            {/* Show the View Result button only on the last question/answer */}
            {currentQuestionIndex === questions.length - 1 ? (
              <button onClick={handleViewResult}>View Score</button>
            ) : (
              <button onClick={handleNextQuestion} disabled={currentQuestionIndex === questions.length - 1}>
                Next →
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResultsPage;