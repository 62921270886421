
// src/pages/HomePage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/HomePage.css'; // Import the CSS file
import axiosInstance from '../axiosInstance';

const HomePage = () => {
  const [username, setUsername] = useState('');
  const [year, setYear] = useState('');
  const navigate = useNavigate();

  // Utility function to capitalize the first letter of each word
  const capitalizeFirstLetter = (name) => {
    return name
      .split(' ') // Split the name into an array of words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter and lowercase the rest
      .join(' '); // Join the words back into a string
  };

  const handleUsernameChange = (e) => {
    const input = e.target.value;
    const formattedUsername = capitalizeFirstLetter(input);
    setUsername(formattedUsername); // Set the formatted username
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      // Save user information in session storage
      sessionStorage.setItem('username', username);

      // Fetch king details by year
      const response = await axiosInstance.get(`/kings/year/${year}/`);
      const kingData = response.data;
      console.log("King Data Response:", kingData);

      // Fetch king achievements using king_id
      const kingId = kingData.id; // Assuming kingData contains the king_id
      const achievementsResponse = await axiosInstance.get(`/achievements/king/${kingId}/`);
      const achievementsData = achievementsResponse.data;

      // Save king details and achievements in session storage
      sessionStorage.setItem('king', JSON.stringify(kingData));
      sessionStorage.setItem('achievements', JSON.stringify(achievementsData));

      // Redirect to King Details page
      navigate(`/king-details`);
    } catch (error) {
      console.error('Error fetching king details or achievements:', error);
      alert('No king found for the selected year or no achievements available.');
    }
  };
  return (
    <div className="homepage">
      {/* Second background image (sub-header section) */}
      <div className="sub-header-image"></div>
      {/* Third background image with the card for the form */}
      <div className="third-background">
        <div className="card">
          <form onSubmit={handleSearch}>
            <input
              type="text"
              placeholder="Enter your full name"
              value={username}
              onChange={handleUsernameChange} // Use the new change handler
              required
            />
            <input
              type="number"
              placeholder="Enter a year (1932 - Present)"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              min="1932"
              max={new Date().getFullYear()}
              required
            />
            <button type="submit">Search</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
